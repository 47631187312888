import React, { useEffect, useState , } from "react";
import { Link } from "react-router-dom";
import "./main.css";
import one from "../../images/h1.jfif";
import two from "../../images/h2.jpg";
import three from "../../images/h3.jfif";
import four from "../../images/h4.JPG";
import img1 from '../../images/h6.jpg';
import img2 from '../../images/a1.jpg';
import bridge1 from '../../images/d1.jpg';
import bridge2 from '../../images/d2.jpg';
import bridge3 from '../../images/d6.jpg';
import bridge4 from '../../images/d3.JPG';
import bridge5 from '../../images/d4.jpg';
import bridge6 from '../../images/d5.jpg';
import s1 from '../../images/s1.jpg';
import s2 from '../../images/s2.jpg';
import s3 from '../../images/s3.jpg';
import s4 from '../../images/s4.jpg';
import s5 from '../../images/s5.jfif';
import s6 from '../../images/s6.jpg';
import s7 from '../../images/s7.jpg';
import s8 from '../../images/s8.jpg';
import s9 from '../../images/s9.jpg';
import s10 from '../../images/s10.jpeg';
import s11 from '../../images/s11.jpg';
import wedding_img from '../../images/wedding_bride3.png';
import logo1 from '../../images/client_logo1.jpg';
import logo2 from '../../images/client-logo2.jpg';
import logo3 from '../../images/client_logo3.jpg';
import logo4 from '../../images/client_logo4.jpg';
import logo5 from '../../images/client_logo5.jpg';
import logo6 from '../../images/client_logo6.jpg';
import logo7 from '../../images/client_logo7.jpg';
import logo8 from '../../images/client_logo8.jpg';
import logo9 from '../../images/client_logo-10.png';
import logo10 from '../../images/logo-11.png';
import logo11 from '../../images/logo-12.png';
import logo12 from '../../images/logo-13.png';
import logo13 from '../../images/logo-14.png';
import logo14 from '../../images/logo-15.png';
import logo15 from '../../images/logo-16.png';
import logo16 from '../../images/logo-17.png';
import logo17 from '../../images/logo-18.png';
import logo18 from '../../images/logo-19.png';
import logo19 from '../../images/logo-20.png';
import logo20 from '../../images/logo-21.png';
import wed_img1 from '../../images/weding_cps.png';
import Footer from '../footer/footer';
import TestimonialSlider from './slider';
import BackGround from "../background/background";
// import ReactGA from "react-ga4";

const images = [one,two, three, four];
const images2 = [bridge1,bridge2,bridge3,bridge4,bridge5,bridge6];

export default function Header() {

  const [currentImage, setCurrentImage] = useState(0);


  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage((currentImage) => currentImage + 1);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);
  
  useEffect(() => {
    // Apply overflow: hidden to prevent horizontal overflow
    document.documentElement.style.overflowX = 'hidden';
  }, []);
  const imgarray = images[currentImage % images.length];
  const imgarray2 = images2[currentImage % images2.length];

  return (
    <div>
      <div className="my-container">
      <div className="image-zoom-container">
        <img
          className="zoomed-image"
          // style={{ maxWidth: "1000px" }}
          src={imgarray}
          alt="eventphoto"
        />
      </div>
      </div>
      <div className="about-v">
      <div className="main-container1">
      <div className="m-row">
        <div className="m-6 m-12">
        <div className="m-text">
        <h3>Vajraksh events is the one stop event organisation.</h3>
        <p>Developing events after a careful study of the brand and considering its objective. Delivering the best possible to generate maximum response towards the products and clients.</p>
        <div className="m-row m-left-row">
          <div className="m-6">
            <div className="vision">
              <h4>Vision</h4>
              <p>We are here to let you enjoy your piece of cake while we walk the extra mile for you and offers a “One stop Shoppe” for all your event management requirements.</p>
            </div>
          </div>
          <div className="m-6">
          <div className="mission">
            <h4>Mission</h4>
            <p>Our humble beginnings have taught us that we are made by our client's happiness and day in and day out we strive to achieve the very best in quality and elegance for the client.</p>
            </div>
          </div>
          <div className="m-button">
          <Link to='/about' className="no-underline"> Read More</Link>
          </div>
        </div>  
        </div>
        </div>
        <div className="m-6 m-12">
          <div className="about-home-img">
          <img className="main_img1" src={img1} alt="main_img" />
          <img className="main_img2" src={img2} alt="main_img" />
          </div>
        </div>
        </div>
      </div>
      </div>
      {/* our services */}
      <div className="m-services">
  <div className="m-container1">
    <div className="m-row1">
    <div className="m-12">
              <div className="m-service-heading">
                <h2>OUR SERVICES</h2>
                {/* <span className="m-heading-ser">SERVICES</span> */}
              </div>
            </div>
      <div className="m-3">
        <div className="ser-box">
          <img src={s1} className="main-bridge" alt="bridge_img" />
          <div className="ser-box-text">
            <h3>Wedding & Receptions</h3>
            <p>Weddings mark the start of a new chapter in a couple's life and celebrate their love and commitment to each other..</p>
            <div className="ser-box-button">
              <Link to='/wedding' className="no-underline"> Read More</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="m-3">
        <div className="ser-box">
          <img src={s2} className="main-bridge" alt="bridge_img" />
          <div className="ser-box-text">
            <h3>Corporate Events</h3>
            <p>Corporate events are important for building and strengthening relationships, promoting and celebrating a company's..</p>
            <div className="ser-box-button">
              <Link to='/corporateevents' className="no-underline"> Read More</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="m-3">
        <div className="ser-box">
          <img src={s3} className="main-bridge" alt="bridge_img" />
          <div className="ser-box-text">
            <h3>MICE Events</h3>
            <p>MICE (Meetings, Incentives, Conferences, and Exhibitions) events play a crucial role in promoting business..</p>
            <div className="ser-box-button">
              <Link to='/miceevents' className="no-underline"> Read More</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="m-3">
        <div className="ser-box">
          <img src={s4} className="main-bridge" alt="bridge_img" />
          <div className="ser-box-text">
            <h3>Movie Publicity</h3>
            <p>Movie publicity and promotions are crucial for building audience awareness and anticipation, creating buzz..</p>
            <div className="ser-box-button">
              <Link to='/movieevents' className="no-underline"> Read More</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="m-3">
        <div className="ser-box">
        <img src={s5} className="main-bridge" alt="bridge_img" />
          <div className="ser-box-text">
            <h3>Birthday parties</h3>
            <p>Movie publicity and promotions are crucial for building audience awareness and anticipation, creating buzz..</p>
            <div className="ser-box-button">
              <Link to='/birthdayevents' className="no-underline"> Read More</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="m-3">
        <div className="ser-box">
        <img src={s6} className="main-bridge" alt="bridge_img" />
          <div className="ser-box-text">
            <h3>Sports Events</h3>
            <p>Sports events serve as a platform for entertainment, excitement, and friendly competition, and can bring..</p>
            <div className="ser-box-button">
              <Link to='/sportsevents' className="no-underline"> Read More</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="m-3">
        <div className="ser-box">
        <img src={s7} className="main-bridge" alt="bridge_img" />
          <div className="ser-box-text">
            <h3>Media Planners</h3>
            <p>Media planners are <br />professionals specialized in creating and executing advertising..</p>
            <div className="ser-box-button">
              <Link to='/mediaevents' className="no-underline"> Read More</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="m-3">
        <div className="ser-box">
          <img src={s8} className="main-bridge" alt="bridge_img" />
          <div className="ser-box-text">
            <h3>Celebrity Management</h3>
            <p>Celebrity management is a critical aspect of the entertainment industry, as it helps to ensure that celebrities</p>
            <div className="ser-box-button">
              <Link to='/celebrityevents' className="no-underline"> Read More</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="m-3">
        <div className="ser-box">
          <img src={s9} className="main-bridge" alt="bridge_img" />
          <div className="ser-box-text">
            <h3>Occasional Events</h3>
            <p>Occasional events create memories, foster community, promote brand awareness, and celebrate milestones.</p>
            <div className="ser-box-button">
              <Link to='/occasionalevents' className="no-underline"> Read More</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="m-3">
        <div className="ser-box">
          <img src={s10} className="main-bridge2" alt="bridge_img" />
          <div className="ser-box-text">
            <h3>Entertainment</h3>
            <p>Entertainment promotes relaxation and stress relief and enhances the overall quality of life</p>
            <div className="ser-box-button">
              <Link to='/entertainmentevents' className="no-underline"> Read More</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="m-3">
        <div className="ser-box">
          <img src={s11} className="main-bridge1" alt="bridge_img" />
          <div className="ser-box-text">
            <h3>SFX Products</h3>
            <p>SFX (Special Effects) products create immersive and captivating experiences in various forms of entertainment..</p>
            <div className="ser-box-button">
              <Link to='/sfxevents' className="no-underline"> Read More</Link>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>
    <div className="meet_our_space">
    <div className="m-container2">
      <div className="m-row2 meet_our_space_row">
        <div className="m-3 m-6 m-12 meet_demo_c1">
        <div className="wedding_hands">
        <img src={wedding_img} alt="" />
        </div>
        </div>
        <div className="m-3 m-6 m-12">
        <div className="meet_our_space_lft_cnt">
        <h4>Happening Moments</h4>
        <div className="abt_sec_home_btn1">
        <Link to='/about' className="no-underline"> Read More</Link>
        </div>
        </div>
        </div>
        <div className="m-4 m-6 m-12">
          <div className="our_place_slic">
            <div className="responsive slick-initialized slick-slider">
              <div className="silk-prew silk-arrow">
                <button className="slick-next slick-arrow"></button>
                <div className="slick-list draggable">
                  <div  className="meet_our_space_slicbox">
                <img src={imgarray2} alt=""/>
                </div>
                </div>
                <button className="slick-next slick-arrow"></button>
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>
    </div>
    {/* our clients */}
    <div className="our-clients-main-container">
    <div className="m-container3">
      <div className="m-row3">
        <div className="m-12">
        <div className="ourclients-main-heading-sec">
        <h2>OUR CLIENTS</h2>
        <p>Clients are the backbone of our Event management industry and play a crucial role in determining the success of an event. Vajra events Maintain clear and open communication with clients which is essential for understanding their needs and expectations and ensures that the event meets their goals.</p>
        </div>
        </div>
        <div className="m-12">
        <div className="clientsview-fist-row-sec ">
        <div className="clients-logos-section">
        <div className="client-sec">
        <img className="logo-size1" src={logo1} alt='logo'/>
        </div>
        <div className="client-sec">
        <img className="logo-size1" src={logo2} alt='logo'/>
        </div>
        <div className="client-sec">
        <img className="logo-size1" src={logo3} alt='logo'/>
        </div>
        <div className="client-sec">
        <img className="logo-size1" src={logo4} alt='logo'/>
        </div>
        <div className="client-sec">
        <img className="logo-size1" src={logo5} alt='logo'/>
        </div>
        <div className="client-sec">
        <img className="logo-size1" src={logo6} alt='logo'/>
        </div>
        <div className="client-sec">
        <img className="logo-size1" src={logo7} alt='logo'/>
        </div>
        <div className="client-sec">
        <img className="logo-size1" src={logo8} alt='logo'/>
        </div>
        <div className="client-sec">
        <img className="logo-size1" src={logo9} alt='logo'/>
        </div>
        </div>
        </div>
        <div className="clientsview-second-row-sec">
        <div className="clients-logos-section">
        <div className="client-sec">
        <img className="logo-size1" src={logo10} alt='logo'/>
        </div>
        <div className="client-sec">
        <img className="logo-size1" src={logo11} alt='logo'/>
        </div>
        <div className="client-sec">
        <img className="logo-size1" src={logo12} alt='logo'/>
        </div>
        <div className="client-sec">
        <img className="logo-size1" src={logo13} alt='logo'/>
        </div>
        <div className="client-sec">
        <img className="logo-size1" src={logo14} alt='logo'/>
        </div>
        <div className="client-sec">
        <img className="logo-size1" src={logo15} alt='logo'/>
        </div>
        <div className="client-sec">
        <img className="logo-size1" src={logo16} alt='logo'/>
        </div>
        <div className="client-sec">
        <img className="logo-size1" src={logo17} alt='logo'/>
        </div>
        <div className="client-sec">
        <img className="logo-size1" src={logo18} alt='logo'/>
        </div>
        <div className="client-sec">
        <img className="logo-size1" src={logo19} alt='logo'/>
        </div>
        <div className="client-sec">
        <img className="logo-size1" src={logo20} alt='logo'/>
        </div> 
        </div>
        </div>
        </div>

      </div>
    </div>
    </div>
    {/* testimonial */}
    <div className="testimonial_sec">
    <div className="demo">
      <div className="m-container4">
        <div className="m-row4">
        <div className="m-7 sm-12">
        <div className="testimonial_heading">
        <h2>Our Testimonial</h2>
        <TestimonialSlider/>
        </div>
        </div>
        <div className="m-5 sm-12">
        <div className="wding_cps">
        <img src={wed_img1} alt=""/>
        </div>
        </div>
        </div>
      </div>
    </div>
    </div>
      <BackGround/>
      <Footer/>
    </div>
  );
}
