import React, { useState } from "react";
import Modal from 'react-modal';
import './vlogin.css';
import dimg from '../images/artboard2.jpeg';
import { VSIGNIN, VSIGNUP } from '../endpoints';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate,Link } from 'react-router-dom';
import forgot from '../images/forgot.webp';
import axios from 'axios';
import * as yup from 'yup';
import eyeOpen from "../images/hide.png";
import eyeClosed from "../images/eye.png";


const validationSchema = yup.object().shape({
  email_or_phone: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
});
const validationSchema1 = yup.object().shape({
  name: yup.string().required("Name is required").matches(/^[A-Za-z\s]+$/, 'Invalid name'),
  email: yup.string().required("Email is required").matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/, 'Invalid email address'),
  phone: yup.string().required("mobile Number is required").matches(/^[6-9]\d{9}$/, 'Invalid phone number'),
  password: yup.string().required("Password is required"),
  confirm_password: yup.string().required("Confirm Password is required"),
  level: yup.string().required("Level is required"),
  address: yup.string().required("address is required"),
});

export default function VenderLogin() {
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
  const [signupModalIsOpen, setSignupModalIsOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [signinformData, setSigninFormData] = useState({
    email_or_phone: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [signupformData, setSignupFormData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    confirm_password: '',
    level:'',
    address:'',
  });
 
  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };
  const togglePasswordVisibility1 = () => {
    setShowPassword1(prevState => !prevState);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(prevState => !prevState);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = '';
    switch (name) {
      case 'name':
        error = value.trim() === '' ? 'Name is required' : '';
        break;
      case 'email':
        error = value.trim() === '' ? '' : '';
        break;
      case 'phone':
        error = value.trim() === '' ? '' : '';
        break;
      case 'password':
        error = value.trim() === '' ? '' : '';
        break;
      case 'confirm_password':
        error = value.trim() === '' ? '' : '';
        break;
      case 'category':
        error = value === '' ? '' : '';
        break;
      case 'address':
        error = value.trim() === '' ? '' : '';
        break;
      default:
        break;
    }
    
    setSignupFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
   
    }
  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setSigninFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const navigate = useNavigate();

  const openLoginModal = () => {
    setLoginModalIsOpen(true);
  };

  const closeLoginModal = () => {
    setLoginModalIsOpen(false);
    window.location.reload();
  };

  const openSignupModal = () => {
    setSignupModalIsOpen(true);
  };

  const closeSignupModal = () => {
    setSignupModalIsOpen(false);
    window.location.reload();
  };
  
  const handleSignupSubmit = (e) => {
    e.preventDefault();
  
    // Check if passwords match
    if (signupformData.password !== signupformData.confirm_password) {
      toast.error('Passwords do not match. Please enter matching passwords.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      return;
    }
  
    // Validate form data
    validationSchema1.validate(signupformData, { abortEarly: false })
      .then(() => {
        axios.post(VSIGNUP, { ...signupformData })
          .then(response => {
            const { data } = response;
            if (data.error) {
              toast.error(data.error, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
              });
            } else {
              console.log(response);
              console.log(signupformData);
              sessionStorage.setItem("email", signupformData.email);
  
              toast.success('Please Check Your email for OTP', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 10000,
              });
              setTimeout(() => {
                navigate("/signupotp");
              }, 5000);
            }
          })
          .catch(error => {
            console.error('Error during signup:', error);
          });
      })
      .catch(err => {
        const validationErrors = {};
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
      });
  };
  
  
  const handleSigninSubmit = (e) => {
    e.preventDefault();
    // Clear errors state
    setErrors({});
  
    validationSchema.validate(signinformData, { abortEarly: false })
      .then(() => {
        axios.post(VSIGNIN, { ...signinformData })
          .then(response => {
            if (response.status === 200) {
              const { data } = response;
              if (data.error === "Incorrect password") {
                toast.error('Incorrect password. Please try again.', {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                });
              } else if (data.error === "User not found") {
                toast.error('User not found. Please check your credentials.', {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                });
              } else if (data.id_token) {
                // Handle successful login
                if (data.message === "Sign in successful") {
                  navigate("/vender");
                  sessionStorage.setItem("email", signinformData.email_or_phone);
                  localStorage.setItem("id_token", response.data.id_token);
                } else if (signinformData.email_or_phone === "admin" && signinformData.password === "admin@123") {
                  localStorage.setItem("id_token", response.data.id_token);
                  navigate("/admin");
                }
              }
            }
          })
          .catch(error => {
            console.error('Error during signin:', error);
          });
      })
      .catch(err => {
        const validationErrors = {};
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
      });
  };
  
  
  return (
    <div className="ModalContainer">
      <div className="flex-container">
        <div className="left-side-d">
          <h2 className="vender-text">Welcome To Vajraksh</h2>
          <div>
          <button className="dlogin-button" onClick={openLoginModal}>
            Login
          </button>
          <button className="dlogin-button1" onClick={openSignupModal}>
            Signup
          </button>
          </div>
          <div>
            <img className='forgot_icon' src={forgot} alt='forgot_icon'/>
          <Link to='/forgotpassword' className="forgotpassword">Forgot Password</Link>
          </div>
        </div>
        <div className="right-side-d">
          <img src={dimg} className='dimg-container' alt='' />
        </div>
      </div>
      <Modal
        isOpen={loginModalIsOpen}
        onRequestClose={closeLoginModal}
        contentLabel="Login Modal"
        className="Model_1"
        style={{
          overlay: {
            zIndex: 1000,
          },
          content: {
            overflow: 'auto',
          },
        }}
      >
        <div className="formContainer1">
          <h3 style={{ textAlign: 'center', color: 'white' }}>Please Enter Your Details</h3>
          <form className='signin_quote_form' onSubmit={handleSigninSubmit}>

            <label className='signin_quote_label' htmlFor="email">Email or Phone:*</label>
            <input className='signin_quote_input' placeholder="Email or Phone" type="text2" id="email_or_phone" name="email_or_phone" onChange={handleChange1} required />
            {errors.email_or_phone && <div className="login-error-message">{errors.email_or_phone}</div>}

            <label className='signin_quote_label' htmlFor="phone">Password*</label>
            <div className="password-input-container">
            <input className='signin_quote_input' type={showPassword ? "text4" : "password"}  placeholder="Password" id="password" name="password" onChange={handleChange1} required />
            <button type="button" onClick={togglePasswordVisibility}>
    <img src={showPassword ? eyeClosed : eyeOpen} alt="Toggle Password Visibility" />
  </button>
  </div>
            {errors.password && <div className="login-error-message">{errors.password}</div>}
            <button className='quote_button_1' type="submit" onClick={handleSigninSubmit}>Submit</button>
            <button className="vlogin_close" onClick={closeLoginModal}>Close</button>
          </form>
        </div>
      </Modal>
      <Modal
        isOpen={signupModalIsOpen}
        onRequestClose={closeSignupModal}
        contentLabel="Signup Modal"
        className="Model_2"
        style={{
          overlay: {
            zIndex: 1000,
          },
          content: {
            overflow: 'auto',
          },
        }}
      >
        <div className="formContainer2">
          <h3 style={{ textAlign: 'center', color: 'white' }}>Please Enter Your Details</h3>
          <form className="signup_quote_form" onSubmit={handleSignupSubmit}>
  <div className="input-group">
    <div className="input-wrapper">
      <label className="quote_label" for="name">Name:*</label>
      <input className="signup_quote_input" placeholder="Name" type="text4" id="name" name="name" onChange={handleChange} pattern="[A-Za-z\s]+" required />
      {errors.name && <div className="error-message">{errors.name}</div>}   
       </div>
    <div className="input-wrapper">
      <label className="quote_label" for="email">Email:*</label>
      <input className="signup_quote_input" type="email" placeholder="Email" id="email" name="email" onChange={handleChange} required />
      {errors.email && <div className="error-message">{errors.email}</div>}
    </div>
  </div>
  <div className="input-group">
    <div className="input-wrapper">
      <label className="quote_label" for="phone">Mobile Number:*</label>
      <input className="signup_quote_input" type="tel" id="phone" placeholder="Mobile number" name="phone" onChange={handleChange} pattern="[0-9]+" required />
      {errors.phone && <div className="error-message">{errors.phone}</div>}
    </div>
    <div className="input-wrapper">
      <label className="quote_label" for="level">Level:*</label>
      <select className="signup_quote_input" id="level" name="level" onChange={handleChange} required>
        <option className="option-signup" value="" disabled selected>Select Level</option>
        <option value="high">High</option>
        <option value="medium">Medium</option>
        <option value="low">Low</option>
      </select>
      {errors.level && <div className="error-message">{errors.level}</div>}
    </div>
  </div>
  <div className="input-group">
  <div className="input-wrapper">
    <label className="quote_label" htmlFor="password">Password:*</label>
    <div className="password-input-container1">
      <input className="signup_quote_input" type={showPassword1 ? "text4" : "password"} placeholder="Password" id="password" name="password" onChange={handleChange} required />
      {errors.password && <div className="error-message">{errors.password}</div>}
      <button type="button" onClick={togglePasswordVisibility1}>
        <img src={showPassword1 ? eyeClosed : eyeOpen} alt="Toggle Password Visibility" />
      </button>
    </div>
  </div>
  <div className="input-wrapper">
    <label className="quote_label" htmlFor="confirm_password">Confirm Password:*</label>
    <div className="password-input-container2">
      <input className="signup_quote_input" type={showPassword2 ? "text4" : "password"} placeholder="Confirm Password" id="confirm_password" name="confirm_password" onChange={handleChange} required />
      {errors.confirm_password && <div className="error-message">{errors.confirm_password}</div>}
      <button type="button" onClick={togglePasswordVisibility2}>
        <img src={showPassword2 ? eyeClosed : eyeOpen} alt="Toggle Password Visibility" />
      </button>
    </div>
  </div>
</div>
  <div className="input-wrapper">
    <label className="quote_label" for="address">Address:</label>
    <textarea className="signup_quote_input" placeholder="Address" id="address" name="address" onChange={handleChange}></textarea>
    {errors.address && <div className="error-message">{errors.address}</div>}
  </div>
  <div className="signup_buttons">
  <button className='quote_button_2' type="submit" onClick={handleSignupSubmit}>Submit</button>
            <button className="vsignup_close" type="button" onClick={closeSignupModal}>Close</button>
            </div>
</form>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
}
