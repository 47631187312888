export const BASE_URL = 'http://100.25.141.212:8080/api';
export const CHAIR_URL = `${BASE_URL}/chairs`;
export const DECORATION_URL = `${BASE_URL}/decoration`;
export const CALCULATE_PRICE_URL = `${BASE_URL}/calculate_price`;
export const NON_VEG_ITEMS = `${BASE_URL}/non_veg_items`;
export const VEG_ITEMS = `${BASE_URL}/veg_items`;
export const FOOD_ITEMS = `${BASE_URL}/food_items`;
export const MAKEUP_ITEMS = `${BASE_URL}/makeup_items`;
export const ENTERTAINMENT = `${BASE_URL}/entertainment_items`;
export const VENUE  = `${BASE_URL}/venues`;
export const TRANSPORT  = `${BASE_URL}/transport`;
export const PHOTOGRAPHY  = `${BASE_URL}/photography`;

export const CORPORATE_DEC= `${BASE_URL}/cedecoration`;
export const CORPORATE_CHAIRS= `${BASE_URL}/cecandt`;
export const CORPORATE_GIFTS= `${BASE_URL}/cegifts`;
export const CORPORATE_SHEILDS= `${BASE_URL}/cesheilds`;

export const BDECORATION= `${BASE_URL}/bdecoration`;
export const BCAKE= `${BASE_URL}/bcake`;
export const BGIFTS= `${BASE_URL}/bgifts`;

export const VSIGNUP =`${BASE_URL}/signup`;
export const VSIGNIN =`${BASE_URL}/signin`;
export const V_OTP =`${BASE_URL}/verify_otp?email=`;

export const VENDER_LIST =`${BASE_URL}/vendorslist`;
export const ADD_EVENT =`${BASE_URL}/add_event`;
export const EVENTS_LIST =`${BASE_URL}/events_list`;
export const VENDOR_QUOTE =`${BASE_URL}/vendor_tendor`;
export const EVENT_CHAT_HISTORY =`${BASE_URL}/event_chat_history`;
export const EVENT_MESSAGE =`${BASE_URL}/eventmsg`;
export const DEVENT_LIST =`${BASE_URL}/devents_list`;
export const VEVENT_HISTORY =`${BASE_URL}/vevent_history`;
export const DEACTVATE_EVENT =`${BASE_URL}/deactivate_event`;
export const VEVENT_LIST =`${BASE_URL}/vevent_list`;
export const ADD_EVENT_ITEMS =`${BASE_URL}/add_event_items?event_id=`;
export const EVENT_ITEMS =`${BASE_URL}/event_items?event_id=`;
export const ASSIGN_CONTRACT =`${BASE_URL}/assign_contract`;
export const CONTRACT_DATA =`${BASE_URL}/contract-data`;
export const FORGOT_PASSWORD =`${BASE_URL}/forgot_password`;
export const RESET_PASSWORD =`${BASE_URL}/reset_password`;

